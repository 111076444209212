@import '../../../../scss/theme-bootstrap';

.tabbed-products-block {
  &__tabs {
    margin: 10px auto 0 auto;
    text-align: center;
    @include breakpoint($medium-up) {
      margin: 20px auto 0 auto;
    }
  }
  &__tabs-content {
    display: inline-block;
  }
  &__tab {
    border-bottom: 1px solid $color-gray-light;
    color: $color-black-2;
    cursor: pointer;
    display: block;
    font-family: $body-font;
    font-size: 12px;
    float: #{$ldirection};
    letter-spacing: 0;
    line-height: 21px;
    padding: 16px 15px;
    text-decoration: none;
    text-transform: uppercase;
    @include breakpoint($medium-up) {
      font-size: 14px;
      padding: 10px 70px;
    }
    &:visited {
      color: $color-black-2;
      text-decoration: none;
    }
    &:not(.current):hover {
      border-bottom: 1px solid $color-black;
      color: $color-black-2;
      text-decoration: none;
    }
    &.current {
      border-bottom: 1px solid $color-black;
      color: $color-black-2;
      font-family: $body-font-bold;
      text-decoration: none;
    }
    &.disabled {
      pointer-events: none;
      cursor: default;
    }
  }
  &__content {
    &.hide-product-budges {
      .product-brief__flag {
        display: none;
      }
    }
  }
  &__content-item {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity 1s ease-in-out;
    width: 100%;
    &.current {
      height: auto;
      overflow: visible;
      opacity: 1;
    }
  }
  .product-brief-carousel {
    border-top: none;
  }
  .homepage-formatter & {
    .block-eyebrow--header,
    .block-headline--header {
      margin-#{$ldirection}: 36px;
    }
  }
  .grid--mpp__carousel {
    animation-duration: 1.5s;
    .carousel-controls {
      .slick-next,
      .slick-prev {
        top: 50%;
        font-size: 0;
      }
      .slick-next:before,
      .slick-prev:before {
        font-size: 20px;
      }
    }
    .grid--mpp__items {
      > .slick-list {
        flex-flow: row wrap;
      }
      > .slick-list,
      > .slick-list > .slick-track,
      .grid--mpp__item,
      .grid--mpp__item > article,
      .grid--mpp__item > article > section,
      .mpp-grid,
      .mpp-grid__wrapper,
      .mpp-grid__item {
        display: flex;
        flex: 1;
        @if $cr22 == false {
          @include breakpoint($landscape-up) {
            min-width: 34%;
          }
        }
      }
      .mpp-grid__wrapper {
        margin: 0;
        max-width: 100%;
        height: auto !important; //Since height is set to 0 through JQuery, important is needed when tabbed_products template loads with product_grid_v2
      }
      .grid--mpp__item {
        height: auto;
        justify-content: center;
        overflow: visible;
      }
      .mpp-grid__item {
        max-width: 100%;
        width: 100%;
        @if $cr22 == false {
          padding: 1em 0;
        } @else {
          margin: 20px auto;
          @include breakpoint($medium-up) {
            padding: 0 20px;
            max-width: 400px;
          }
        }
        &.slick-slide {
          min-height: auto;
          padding: 0 20px;
        }
        .product-brief-shades,
        .product-brief__shades {
          width: 100%;
          @if $cr22 == false {
            @include breakpoint($medium-up) {
              width: auto;
            }
          }
        }
        .product-brief__shades {
          .product-brief-shades__grid {
            margin-bottom: 0;
            &--layout {
              max-width: 100%;
            }
            @if $cr22 == false {
              max-width: 280px;
              @include breakpoint($medium-up) {
                max-width: 220px;
              }
              .slick-prev {
                #{$ldirection}: -23px;
                @include breakpoint($medium-up) {
                  #{$ldirection}: -10px;
                }
              }
              .slick-next {
                #{$rdirection}: -23px;
                @include breakpoint($medium-up) {
                  #{$rdirection}: -10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
